/*Custom style*/
.custom-icon-template:before {
    content: url("../images/template.png");
    height: 64px;
}

.custom-icon-app:before {
    content: url("../images/administrative-tools.png");
    height: 64px;
}

.custom-icon-ecommerce:before {
    content: url("../images/e-commerce.png");
    height: 64px;
}

.grecaptcha-badge {
    visibility: hidden;
}

#footer .address {
    min-width: 250px;

    .shadow-button {
        color: #0b0b0b!important;
    }
}

.contact-decode {
    padding: 0.5rem 2rem;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0.5rem;
    border-radius: 30px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: inherit;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.1), 0 4px 15px 0 rgba(0, 0, 0, 0.08);

    &:hover {
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.28), 0 4px 15px 0 rgba(0, 0, 0, 0.25);
        transform: translateY(-2px);
    }

    span {
        margin-right: 1rem;
    }
}

.loader {
    align-items: center;

    i {
        color: #3dbf28;
        margin-right: 5px;
        margin-left: 17px;
        font-size: 20px;
    }
}

.align-items-center {
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
}

.text-center {
    text-align: center;
}

#gdynia-image {
    position: relative;
    background: url("../images/background/cliff.jpg") no-repeat center center fixed;
    background-size: cover;
    height: 400px;

    .overlay {
        opacity: 0.7;
    }
}

#subscribe {

    .title {
        flex: 1 0;
    }

    .text-center {
        text-align: center;
    }
}

#technologies, #tools {
    overflow: hidden;

    .tile {
        box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 10px;
        position: relative;
        z-index: 10;
        overflow: visible;

        figure {
            margin: 1em;
        }

        img {
            max-width: 100%;
            filter: brightness( 100% ) contrast( 99% ) saturate( 0% ) blur( 0px ) hue-rotate( 0deg );
            transition-duration: .3s;
            transition-property: transform;

            &:hover {
                filter: none !important;
                transform: scale(0.9);
            }
        }
    }
}

#prices {

    .input-field {

        ul {
            position: absolute;
            bottom: 0;
            margin: 0;
            font-size: 80%;
            color: red;

            li {
                padding-left: 0;
            }
        }

        input {
            margin-bottom: 35px;
            background-color: transparent !important;
        }
    }
}

.price__service {
    text-align: center;
    background: white;
    padding: 20px;
    position: absolute;
    width: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%);
    bottom: 25px;
    z-index: 10;

    span {
        color: rgba(0, 0, 0, 0.87);
    }
}

.price__button {
    padding: 0.5rem 40px;
    font-size: 1.4rem;
    color: #ffffff;
    margin-top: 2rem;
    transition: all 0.4s ease-out;
    display: inline-block;
    border: none;
    border-radius: 10px;
    outline: none;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.1), 0 4px 15px 0 rgba(0, 0, 0, 0.08);

    &:hover {
        transform: translateY(-2px);
    }
}

.price__content {
    width: 80%;
    margin: 5px auto;

    label {
        color: rgba(0, 0, 0, 0.87);
    }

    p {
        color: rgba(0, 0, 0, 0.87);
        font-size: 1rem;
    }
}

.theme-color {
    color: #1DA07B;
}

.d-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &.adjectives {

        div:last-of-type {

            label {
                padding-left: 0;
                padding-right: 35px;

                &:before {
                    left: auto;
                    right: 0;
                }

                &:after {
                    left: auto;
                    right: 0;
                }
            }
        }
    }
}

.flex33 {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
}

.team-member__container {
    max-width: 800px;
    margin: 0 auto;
}

.pricing-form__agreement {

    ul {
        position: static!important;
        margin: 0 auto!important;
    }
}

.alert {
    position: relative;
    text-align: center;
    margin: 1rem 0;
    padding: 1rem;
    background-color: #fff;
    transition: box-shadow .25s;
    border-radius: 2px;

    &-danger {
        color: #F44336;
        border: 1px solid #F44336;
    }

    &-success {
        color: #4caf50;
        border: 1px solid #4caf50;
    }
}

.slick-slider {
    margin: 30px auto 50px;
}

.gallery-for {

    ::-webkit-scrollbar-track
    {
        border: 5px solid #F6F9F4;
        background-color: #b2bec3;
    }

    ::-webkit-scrollbar
    {
        width: 15px;
        background-color: #dfe6e9;
    }

    ::-webkit-scrollbar-thumb
    {
        width: 10px;
        background-image: linear-gradient(45deg, #0080FF, #408000);
        border-radius: 10px;
        -webkit-box-shadow: rgba(0,0,0,.12) 0 3px 13px 1px;
    }
}

.gallery-for__item-image {
    max-height: 500px;
    overflow-y: scroll;
}

.gallery-nav__item {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

#features .iphone, #faq .iphone {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    transform-origin: bottom center;
    margin: 0 auto;
    $unit: 60vmin/2.525;
    width: $unit;
    height: 2.15*$unit;
    line-height: 2.165*$unit;
    border: $unit/15.625 solid #111;
    border-radius: $unit/9.86;
    z-index: 200;

    &--pagespeed {
        background-image: url('../images/Phone/pagespeed.jpg');
    }

    &--contact {
        background-image: url('../images/Phone/contact.jpg');
    }

    &--kurier {
        background-image: url('../images/Phone/form.jpg');
    }

    &--zafeir {
        background-image: url('../images/Phone/zafeir.jpg');
    }

    &--user {
        background-image: url('../images/Phone/user.jpg');
    }
}

#showcase img {
    max-width: 100%;
    width: auto;
    padding: 15px 10px;
}

#showcase .iphone, #showcase .ipad, #showcase .asus {
    display: block;
    color: #fff;
    text-align: center;
    font-family: 'SF Pro Display', Helvetica Neue, Arial, sans-serif;
    letter-spacing: -.022em;
    font-size: 2.5vmin;
    text-shadow: 0 0 0.5em #000;
    background-color: #000;
    background-size: auto 200%, cover;
    background-repeat: no-repeat, no-repeat;
    border: solid #111;
    position: relative;
    box-shadow: 0 0.5em 2em 0.2em rgba(0, 0, 0, 0.33), 0 0 0 0.5px #000 inset;
    transition: all .1s linear, line-height 0s linear;
    background-position: 50% 100%, top;
    transform-origin: bottom center;
    margin: 0 auto;

    &:hover {
        color: transparent;
        text-shadow: 0 0 0 transparent;
        background-position: 50% -100%, top;
        transition: all .15s linear, line-height 5s linear;
        line-height: 0;
    }
}

#showcase .iphone {
    $unit: 55vmin/2.525;
    width: $unit;
    height: 2.15*$unit;
    line-height: 2.165*$unit;
    border-width: $unit/15.625;
    border-radius: $unit/9.86;
    z-index: 200;

    &--kurier {
        background-image: linear-gradient(rgba(0,0,0,0), #111), url('../images/projects/tanikurier-mobile.jpg');
    }
}

#showcase .ipad {
    $unit: 30vmin;
    height: 1.43*$unit;
    width: $unit;
    line-height: 1.43*$unit;
    border-width: $unit/20;
    border-radius: $unit/14;
    background-image:  linear-gradient(rgba(0,0,0,0), #111), url('../images/projects/tanikurier-kontakt.jpg');
    z-index: 100;
}

#showcase .asus {
    $unit: 30vmin;
    width: 1.6*$unit;
    height: $unit;
    line-height: 0.90*$unit;
    border-width: $unit/25;
    border-bottom-width: $unit/10;
    border-radius: $unit/16;
    z-index: 100;

    &--kurier {
        background-image:  linear-gradient(rgba(0,0,0,0), #111), url('../images/projects/tanikurier.jpg');
    }

    &--zafeir {
        background-image:  linear-gradient(rgba(0,0,0,0), #111), url('../images/projects/zafeir-full.jpg');
    }
}

// Modify materialize
@media only screen and (min-width: 993px) {
    .container {
        width: 80%
    }
}

@media (max-width: 768px) {
    #features .iphone, #faq .iphone {
        $unit: 90vmin/2.525;
        width: $unit;
        height: 2.15*$unit;
        line-height: 2.165*$unit;
        border: $unit/15.625 solid #111;
        border-radius: $unit/9.86;
    }

    #showcase .iphone {
        $unit: 70vmin/2.525;
        width: $unit;
        height: 2.15*$unit;
        line-height: 2.165*$unit;
        border: $unit/15.625 solid #111;
        border-radius: $unit/9.86;
    }

    #showcase .ipad {
        $unit: 50vmin;
        height: 1.43*$unit;
        width: $unit;
        line-height: 1.43*$unit;
        border-width: $unit/20;
        border-radius: $unit/14;
    }

    #showcase .asus {
        $unit: 50vmin;
        width: 1.6*$unit;
        height: $unit;
        line-height: 0.90*$unit;
        border-width: $unit/25;
        border-bottom-width: $unit/10;
        border-radius: $unit/16;
    }
}
