/*
* Perla - Perla Template
* Build Date: September 2016
* Author: perleTheme Template
* This is a premium product available exclusively here : https://themeforest.net/user/perletheme
*/
/*
  TABLE OF CONTENTS
  --------------------------------
   TABLE OF CONTENTS
  --------------------------------
  *. 1 - Fonts ...................
  *. 2 - General Styles ..........
  *. 3 - Pre Loader Styles .......
  *. 4 - NavBar Styles ...........
  *. 5 - Header Styles ...........
  *. 6 - About Styles ............
  *. 7 - Features Styles .........
  *. 8 - Showcase Styles .........
  *. 9 - Subscribe Styles .......
  *. 10 - Screenshots Styles .....
  *. 11 - Testimonials Styles ....
  *. 12 - Team Styles ............
  *. 13 - Download Styles ........
  *. 14 - News Styles ............
  *. 15 - Video Styles ...........
  *. 16 - Prices Styles ..........
  *. 17 - Statistics Styles ......
  *. 18 - FAQs Styles ............
  *. 19 - Map Styles .............
  *. 20 - Footer Styles ..........
  *. 21 - Copy Rights Styles .....
  *. 22 - Animated Demos Styles ..
  *. 23 - Blog Pages .............
  *. 24 - 404 Page ...............
  *. 25 - Media Queries ..........
  --------------------------------
*/
/* ------------------------------------- */
/* *. 1 - Fonts ........................ */
/* ------------------------------------- */

/* Google Fonts */
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500");

/* ------------------------------------- */
/* *. 2 - General Styles ............... */
/* ------------------------------------- */

html {
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Poppins', sans-serif, "proxima-nova", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: 0;
  height: 100%;
}

h1, h3, h4 {
  color: #484848;
  font-family: 'Anurati', sans-serif, "proxima-nova", "Helvetica Neue", Helvetica, Arial;
}

h2, h5, h6 {
  color: #484848;
  font-family: 'Poppins', sans-serif, "proxima-nova", "Helvetica Neue", Helvetica, Arial;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.2rem;
}

p {
  font-size: 1.1rem;
  color: #7b7b7b;
}


//Padding and margins classes
.p-tb-1 {
  padding: 1rem 0;
}

.p-tb-2 {
  padding: 2rem 0;
}

.p-tb-3 {
  padding: 3rem 0;
}

.p-b-1 {
  padding-bottom: 1rem;
}

.p-b-2 {
  padding-bottom: 2rem;
}

.p-b-3 {
  padding-bottom: 3rem;
}

.p-t-1 {
  padding-top: 1rem;
}

.p-t-2 {
  padding-top: 2rem;
}

.p-t-3 {
  padding-top: 3rem;
}

.m-tb-1 {
  margin: 1rem 0;
}

.m-tb-2 {
  margin: 2rem 0;
}

.m-tb-3 {
  margin: 3rem 0;
}

.m-b-1 {
  margin-bottom: 1rem;
}

.m-b-2 {
  margin-bottom: 2rem;
}

.m-b-3 {
  margin-bottom: 3rem;
}

.m-t-1 {
  margin-top: 1rem;
}

.m-t-2 {
  margin-top: 2rem;
}

.m-t-3 {
  margin-top: 3rem;
}

//Styles for The overlay on sections
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

//Styles for the gradient color
.gradient-color {
  background: #000428;
  background: linear-gradient(135deg, #000428, #0074da);
}
//Styles for each section title
.reflection-text {
  position: relative;
  color: rgb(53, 53, 53);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 500;
  &::after {
    content: '';
    bottom: 0;
    position: absolute;
    width: 150px;
    left: calc(50% - 75px);
    height: 20px;
  }
}
//White Break line after every section header with colored background
.reflection-text-white::after {
  background: url(../images/line-break/line-break-white.svg) no-repeat;
}
//Break line after every section header
.reflection-text:not(.reflection-text-white)::after {
  background: url(../images/line-break/line-break-10.svg) no-repeat;
}

//Padding for each section
.main-section {
  padding: 5rem 0;
}

//Styles for hamburger icon
.very_small_hamburger {
  padding-top: 1.5rem;
  svg {
    width: 80px;
    height: 50px;
    cursor: pointer;
    transform: translate3d(0, 0, 0);
  }
  path {
    fill: none;
    transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 40px;
    stroke-linecap: round;
    stroke: #ffffff;
    stroke-dashoffset: 0px;
  }
  path.top,
  path.bottom {
    stroke-dasharray: 240px 950px;
  }
  path.middle {
    stroke-dasharray: 240px 240px;
  }
  &.open path.top,
  &.open path.bottom {
    stroke-dashoffset: -650px;
  }
  &.open path.middle {
    stroke-dashoffset: -115px;
    stroke-dasharray: 1px 220px;
  }
}

.ham-cont {
  .very_small_hamburger {
    padding-top: 0;
  }
  margin-left: -11px;
  margin-top: -2px;
  svg {
    width: 60px;
    height: 40px;
  }
}

//Styles for main title
.title-link {
  position: relative;
  overflow: hidden;
  font-family: 'Anurati', sans-serif, "proxima-nova", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  color: inherit;
  text-transform: uppercase;
  font-size: 1.5rem;
  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    border-width: 2px 0;
    color: #ffffff;
    border-style: solid;
    left: 0;
    transform: translate3d(-101%,0,0);
    transition: transform 0.5s;
  }
  &:hover::before {
    transform: translate3d(0,0,0);
  }
  span {
    display: inline-block;
    position: relative;
    transform: perspective(1000px) rotate3d(0,1,0,0deg);
    transition: transform 0.5s, color 0.5s;
  }
  &:hover span {
    color: black;
    transform: perspective(1000px) rotate3d(0,1,0,360deg);
  }
  &.blacky:hover span {
    color: #0097CD;
  }

  span:nth-child(7),
  &:hover span:first-child {
    transition-delay: 0s;
  }

  span:nth-child(6),
  &:hover span:nth-child(2) {
    transition-delay: 0.05s;
  }

  span:nth-child(5),
  &:hover span:nth-child(3) {
    transition-delay: 0.1s;
  }

  span:nth-child(4),
  &:hover span:nth-child(4) {
    transition-delay: 0.15s;
  }
  span:nth-child(3),
  &:hover span:nth-child(5) {
    transition-delay: 0.2s;
  }
  span:nth-child(2),
  &:hover span:nth-child(6) {
    transition-delay: 0.25s;
  }
  span:first-child,
  &:hover span:nth-child(7) {
    transition-delay: 0.3s;
  }
}

//Styles for Buttons with Shadoes
.shadow-button {
  color: #0b0b0b;
  border: 1px solid #0b0b0b;
  margin-top: 2rem;
  border-radius: 30px;
  box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.18), 0 1px 9px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.4s ease-out;
  display: inline-block;
  .fa-arrow-right {
    color: inherit;
    opacity: 0;
    transform: translateX(5px);
    transition: transform 0.2s ease-out 0.1s, opacity 0.3s ease-out;
  }
  &:hover {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.28), 0 4px 15px 0 rgba(0,0,0,0.25);
    transform: translateY(-2px);
    .fa-arrow-right {
      opacity: 1;
      transform: translateX(20px);
    }
  }
}

//Styles for each section main paragraph
.desc {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
}

//Styles for Owl Carousal Nav and Dots
.owl-nav {
  z-index: -1;
  position: absolute;
  top: 30%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  left: 0;
  margin: 0;
  color: #4e4e4e;
  i {
    font-size: 3rem;
    padding: 1rem;
  }
  .disabled {
    opacity: 0.3;
    cursor: default !important;
  }
  .owl-prev {
    position: absolute;
    left: -70px;
  }
  .owl-next {
    position: absolute;
    right: -70px;
  }
}
.owl-dots{
  padding-top: 1rem;
  margin-top: 0.5rem;
  text-align: center;
  .owl-dot {
    display: inline-block;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
    }
  }
  .owl-dot span {
    background-color: #c0c0c0;
    display: inline-block;
    height: 2px;
    margin: 5px 7px;
    opacity: 1;
    width: 22px;
  }
  .owl-dot.active span {
    background-color: #000000;
  }
}

//For icons and anchor links
i:focus,
a:focus,
a:visited {
  outline: none;
}

//Hidden Input Field
.hidden {
  display: none;
}


/* ------------------------------------- */
/* *. 3 - Pre Loader Styles ............ */
/* ------------------------------------- */
.pre-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  z-index: 10000;
  .loader {
    width: 2.5em;
    height: 2.5em;
    position: absolute;
    top: calc(50% - 1.25em);
    left: calc(50% - 1.25em);
    transform: rotate(165deg);
  }
  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 0.5em;
    height: 0.5em;
    border-radius: 0.25em;
    transform: translate(-50%, -50%);
  }
  .loader:before {
    animation: before 2s infinite;
  }
  .loader:after {
    animation: after 2s infinite;
  }

  @keyframes before {
    0% {
      width: 0.5em;
      box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
    }
    35% {
      width: 2.5em;
      box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75), 0 0.5em rgba(111, 202, 220, 0.75);
    }
    70% {
      width: 0.5em;
      box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75), 1em 0.5em rgba(111, 202, 220, 0.75);
    }
    100% {
      box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
    }
  }
  @keyframes after {
    0% {
      height: 0.5em;
      box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
    }
    35% {
      height: 2.5em;
      box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75), -0.5em 0 rgba(233, 169, 32, 0.75);
    }
    70% {
      height: 0.5em;
      box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75), -0.5em 1em rgba(233, 169, 32, 0.75);
    }
    100% {
      box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
    }
  }
}


/* ------------------------------------- */
/* *. 4 - NavBar Styles ................ */
/* ------------------------------------- */

#main-nav {
  box-shadow: none;
  padding: 0 1rem;
  background: none;
  position: fixed;
  top: 0;
  z-index: 104;
  transition: all 0.2s ease-in-out;
  //Hide NavBar when scrolling down
  &.nav-up {
    top: -60px;
  }
  //Give NavBar a background color
  #nav-color {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
  }
  .nav-wrapper {
    max-width: 1190px;
    margin: 0 auto;
  }
  .logo {
    width: 120px;
  }
  .brand-logo {
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 1.4rem;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    .logo {
      max-width: 100%;
      padding-right: 1rem;
      width: 60px;
    }
    .title-link {
      line-height: 30px;
    }
  }
  .title {
    width: 100px;
  }
  li {
    position: relative;
  }
  //NavBar Menu Links
  a {
    color: inherit ;
    font-size: 1.1rem;
    font-weight: 500;
  }
  .menu-item:hover {
    background: none;
  }
  a:not(.no-bg).active {
    background-color: rgba(214, 214, 214, 0.3);
  }

  //For DropDown in Main Nav
  .dropdown-button i{
    transition: all 0.2s ease-out;
    font-size: 1rem;
    margin-left: 0.3rem;
  }
  .dropdown-button.active {
    i {
      transform: rotate(180deg);
    }
  }
  .dropdown-content {
    background-color: #252525;
    a {
      color: #ffffff;
      &:hover {
        background-color: #5e5e5e;
      }
    }
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  }

  //Social Menu Icon
  .fixed-action-btn {
    position: static;
    padding-top: 0;
    .btn-floating {
      width: 40px;
      height: 40px;
      line-height: 40px;
      margin: 0 0 0 15px;
      i {
        line-height: 40px;
      }
    }
    ul {
      bottom: auto;
      li {
        margin-bottom: 0;
      }
      i {
        line-height: 40px;
      }
    }
  }

  //Hamburger Icon to open Side Nav
  .button-collapse {
    height: 100%;
    position: absolute;
    right: 0;
    align-items: center;
    margin: 0;
  }

  //Side Nav Styles
  .side-nav {
    background-color: #252525;
    z-index: 1000;
    max-width: 80vw !important;
    a {
      color: white;
      border-bottom: 1px solid grey;
      margin: 0 1rem;
    }
    .home {
      margin-top: 2rem;
      height: auto;
      text-align: center;
      a {
        height: auto;
        text-transform: uppercase;
        font-size: 1.5rem;
      }
    }

    //For DropDown inside Side Nav
    .collapsible-header {
      padding: 0 32px;
      position: relative;
      i {
        position: absolute;
        right: 0;
        color: inherit;
        font-size: 1rem;
        transition: all 0.2s ease-out;
      }
      &.active {
        i {
          transform: rotate(180deg);
        }
      }
    }

    .collapsible-body {
      background-color: inherit;
      a {
        display: block;
        padding: 0 44px;
      }
    }
  }

  //Side Nav Social Icons
  .social {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    a {
      display: inline-block;
      border-bottom: none;
      padding: 0;
      margin: 0;
    }
    i {
      border-radius: 100px;
      font-size: 1.4rem;
      width: 45px;
      height: 45px;
      transition: all 0.3s ease-in;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      margin: 0 0.5rem;
    }
    i:hover {
      box-shadow: 0 10px 10px rgba(185, 185, 185, 0.1);
    }
  }
}

//Styles for the side nav overlay for side nav
#sidenav-overlay {
  z-index: 101;
}
.drag-target {
  z-index: 101;
}


/* ------------------------------------- */
/* *. 5 - Header Styles ................ */
/* ------------------------------------- */

#main-header {
  position: relative;
  height: 100vh;
  min-height: 700px;
  max-height: 900px;
  background: url('../images/background/aurora.jpg');
  background-size: cover;
  color: #ffffff;
  padding-top: 64px;
  .gradient-color {
    z-index: 0;
  }
  .overlay {
    opacity: 0.7;
  }
  .header-section {
    position: relative;
    height: 100%;
    color: inherit;
    display: flex;
    align-items: center;
  }
  .owl-header {
    position: relative;
    width: 25%;
    z-index: 101;
  }
  iframe {
    max-width: 100%;
    width: 600px;
    height: 600px;
  }
  //styles for ripples background slider
  #ripples {
    position: absolute;
    left: 0;
    top: 45%;
    width: 100%;
    height: auto;
    z-index: 103;
    .arrows {
      position: relative;
      top: 40%;
      margin: 0 2rem;
      .scene-next {
        position: absolute;
        right: 0;
        top: 0;
      }
      .scene-prev {
        position: absolute;
        top: 0;
        left: 0;
      }
      i {
        font-size: 1.55rem;
      }
    }
    canvas {
      display: block;
      position: absolute;
      z-index: 100;
      min-height: 700px;
      max-width: 100vw;
      overflow: hidden;
    }
  }
  .slide-wrapper {
    display: none;
  }
  .header-text {
    text-align: center;
    position: relative;
    padding-right: 4rem;
    width: 60%;
    margin: 0 auto;
    z-index: 101;
  }
  .title-link {
    letter-spacing: 1rem;
    font-size: 8vw;
    z-index: 101;
  }
  .description {
    color: inherit;
  }
  .slogan {
    text-align: center;
    border: 2px solid #fff;
    border-radius: 30px;
    display: inline-block;
    padding: 0.2rem 3rem;
    word-spacing: 3px;
    font-size: 0.85rem;
    color: #fff;
    text-transform: uppercase;
    margin: 1rem;
    cursor: pointer;
  }
  a {
    color: inherit;
    padding: 0.5rem;
    font-size: 1rem;
  }
  //Count Down Styles
  .apperle-count-down-wrapper {
    h4 {
      color: inherit;
    }
    #clockdiv{
      font-family: sans-serif;
      color: #fff;
      display: inline-block;
      font-weight: 100;
      text-align: center;
      font-size: 30px;
    }
    #clockdiv > div{
      padding: 10px;
      margin: 5px 0;
      border-radius: 3px;
      background: #1994bf;
      display: inline-block;
    }
    #clockdiv div > span{
      padding: 15px;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.24);
      display: inline-block;
    }
    .smalltext{
      padding-top: 5px;
      font-size: 16px;
    }
  }
}


/* ------------------------------------- */
/* *. 6 - About Styles ................. */
/* ------------------------------------- */

#about-us {
  .col:hover {
    i {
      box-shadow: 0 20px 20px rgba(0, 0, 0, 0.22);
    }
  }
  .icon {
    i {
      border-radius: 100px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
      width: 100px;
      height: 100px;
      transition: all 0.3s ease-in;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      color: #ffffff;
    }
  }
  img {
    max-width: 120px;
    max-height: 120px;
  }
}


/* ------------------------------------- */
/* *. 7 - Features Styles .............. */
/* ------------------------------------- */

#features {
  position: relative;
  background-size: cover;
  color: #ffffff;
  .overlay {
    opacity: 0.7;
  }
  .container {
    position: relative;
    z-index: 10;
  }
  .single-feature {
    padding: 0.5rem;
  }
  .reflection-text,
  h5,
  p,
  a,
  i {
    color: inherit;
  }
  .feature-left a{
    text-align: right;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -20%;
      width: 0;
      height: 2px;
      background-color: #0a0e0a;
      opacity: 0;
      transform: translateY(-50%);
      transition: width 0.4s ease-out;
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: -21%;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #0a0e0a;
      opacity: 0;
      transform: translateY(-48%);
      transition: opacity 0.4s ease-out;
    }
  }
  .feature-right a{
    text-align: left;
    transition: width 0.4s ease-out;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: -20%;
      width: 0;
      height: 2px;
      background-color: #0a0e0a;
      opacity: 0;
      transform: translateY(-50%);
      transition: width 0.4s ease-out;
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -21%;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #0a0e0a;
      opacity: 0;
      transform: translateY(-48%);
      transition: opacity 0.4s ease-out;
    }
  }
  .feature-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    position: relative;
    z-index: 10;
    overflow: visible;
    &.active {
      box-shadow: 0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);;
      i::after {
        animation: rotateEffect 8s linear infinite;
        display: block;
      }
    }
    &.active::after {
      opacity: 1;
      width: 30%;
    }
    &.active::before {
      opacity: 1;
    }
    i {
      position: relative;
      margin: 0 1rem;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
      text-align: center;
      font-size: 2rem;
      line-height: 4rem;
      background: rgba(0, 0, 0, 0.42);
    }
    i::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 2px dashed white;
      top: 0;
      left: 0;
      display: none;
    }

    @keyframes rotateEffect {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
  .images-slider .owl-features {
    max-width: 300px;
    margin: 0 auto;
  }
}

/* ------------------------------------- */
/* *. 8 - Showcase Styles .............. */
/* ------------------------------------- */

#showcase {
  position: relative;
  overflow: hidden;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.05;
  }
  .container,
  h3 {
    position: relative;
    z-index: 20;
  }
  .row:last-child {
    padding-bottom: 0;
  }
  .col {
    position: relative;
  }
  .title {
    font-size: 110%;
  }
  img {
    max-width: 80%;
    width: 500px;
  }
  .absolute-image {
    position: absolute;
    left: 65px;
    top: 50px;
  }
  .opposite-container {
    display: flex;
  }
  .opposite {
    order: -1;
  }
  .opposite .absolute-image {
    left: 15px;
    top: 50px;
  }
  .info {
    display: flex;
    i {
      padding-right: 1rem;
      -webkit-background-clip: text;
      color: transparent;
      font-size: 1.7rem;
    }
  }
}

/* ------------------------------------- */
/* *. 9 - Subscribe Styles ............ */
/* ------------------------------------- */

#subscribe {
  position: relative;
  background: rgb(54, 54, 54);
  background: -webkit-linear-gradient(29deg, rgb(35, 35, 35), rgb(71, 71, 71)) ;
  background: linear-gradient(29deg, rgb(35, 35, 35), rgb(71, 71, 71));
  color: #ffffff;
  overflow: hidden;
  .container {
    position: relative;
    z-index: 10;
    margin-bottom: 0;
  }
  .subscribe-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .title{
    color: inherit;
    padding: 2rem 0 0;
    margin: 0 0 2rem;
    text-align: left;
    flex: 1 0 auto;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 500;
  }
  form {
    position: relative;
    padding-left: 0;
    padding-right: 1rem;
    margin: 0;
    flex: 1 0 auto;
  }
  input {
    background-color: #ffffff;
    border-radius: 30px;
    padding: 1rem 0 1rem 1rem;
    color: #0b0b0b;
    margin-bottom: 0;
  }
  label {
    padding: 0.5rem 3rem;
    font-weight: 500;
    font-size: 1.3rem;
    &::after {
      transform: translateY(75px);
      font-weight: 500;
    }
  }
  label.active {
    transform: translateY(-37px) scale(0.8);
    font-size: 1.3rem;
    color: #d6d6d6;
  }
  button {
    border-radius: 0 30px 30px 0;
    width: 10rem;
    border: none;
    display: inline-block;
    position: absolute;
    right: -1px;
    overflow: hidden;
    top: 0;
    height: 100%;
    &:hover {
      .first {
        transform: translateX(0);
      }
      .second {
        transform: translateX(200px);
      }
    }
  }
  i {
    font-size: 2rem;
    transition: all 0.2s ease-in-out;
  }
  .fa-spinner,
  .fa-check-circle-o {
    color: #3dbf28;
  }
  .fa-times-circle {
    color: #ff261e;
  }
  .first {
    position: absolute;
    transform: translateX(-200px);
    color: #0b0b0b;
  }
  .alert-message {
    text-align: center;
  }
}


/* ------------------------------------- */
/* *. 10 - Screenshots Styles .......... */
/* ------------------------------------- */

#screenshot {
  .container {
    position: relative;
  }
  .carousel {
    height: 600px;
    margin-top: -10rem;
  }
  .navigation {
    position: relative;
    z-index: 1000;
    width: 100%;
    font-size: 2rem;
    line-height: 0;
    i {
      color: inherit;
    }
  }
  .nav-prev,
  .nav-next {
    display: inline-block;
    padding: 1rem 1rem 0;
    cursor: pointer;
    &:hover i{
      color: #a1999e;
      transition: color 0.5s;
    }
  }
}


/* ------------------------------------- */
/* *. 11 - Testimonials Styles ......... */
/* ------------------------------------- */

#testimonials {
  position: relative;
  background: url('http://placehold.it/412x412') repeat ;
  color: #ffffff;
  .overlay {
    opacity: 0.6;
  }
  .container {
    position: relative;
    z-index: 10;
  }
  .reflection-text {
    color: inherit;
  }
  img {
    border: 5px solid #ffffff;
    width: 120px;
    margin: 1rem auto;
  }
  p {
    max-width: 700px;
    margin: 0 auto;
    color: inherit;
  }
  h5,
  h6 {
    color: inherit;
  }
  i {
    color: #fac918;
  }
}


/* ------------------------------------- */
/* *. 12 - Team Styles ................. */
/* ------------------------------------- */

#team {
  figure {
    position: relative;
    text-align: center;
    cursor: pointer;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    &:hover img{
      opacity: 0.8;
      transform: scale(1.1);
    }
    img {
      position: relative;
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      transition: all 0.3s ease-out;
    }
    figcaption {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      opacity: 0;
      color: #fff;
      transition: all 1s;
    }
    .name {
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
      max-width: 600px;
      margin: -0.4rem auto 0;
    }
  }
  .team-name {
    color: transparent;
    -webkit-background-clip: text;
    margin: 0;
    padding: 1rem;
    border-top: none;
  }
  .icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transition: opacity 0.35s, transform 0.35s;
    transform: translate3d(-50%,-65%,0);
    transform-origin: 50%;
    width: 100px;
    text-transform: none;
    font-size: 121%;
    line-height: 2;
  }

  figcaption::before {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 200px;
    height: 200px;
    border: 2px solid #fff;
    content: '';
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;
    transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-45deg) scale3d(0,0,1);
    transform-origin: 50%;
    background: inherit;
  }

  a {
    color: #fff;
    i {
      opacity: 0;
      transition: all 0.35s;
      font-size: 2rem;
      padding: 0.5rem;
      cursor: pointer;
      &:hover {
        color: #001a1d;
      }
    }
  }

  p a:first-child i {
    transform: translate3d(-60px,-60px,0);
  }

  p a:nth-child(2) i {
    transform: translate3d(60px,-60px,0);
  }

  p a:nth-child(3) i {
    transform: translate3d(-60px,60px,0);
  }

  p a:nth-child(4) i {
    transform: translate3d(60px,60px,0);
  }

  figure:hover {
    figcaption::before {
      opacity: 0.9;
      transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-45deg) scale3d(1,1,1);
    }
    figcaption {
      opacity: 0.9;
    }
    p i:empty {
      transform: translate3d(0,0,0);
      opacity: 1;
    }
  }
}

/* ------------------------------------- */
/* *. 13 - Download Styles ............. */
/* ------------------------------------- */

#download {
  position: relative;
  background:url("../images/background/balloon.jpg") center fixed;
  color: #ffffff;
  overflow: hidden;
  .reflection-text {
    color: inherit;
  }
  p {
    color: inherit;
    text-align: left;
    margin-left: 0;
  }
  p {
    max-width: 500px;
  }
  .overlay {
    opacity: 0.7;
  }
  .container {
    position: relative;
    z-index: 10;
  }
  a {
    padding: 0.6rem 2rem;
    display: inline-flex;
    margin: 1rem 0.5rem;
    border-radius: 30px;
    border: 1px solid #ffffff;
    align-items: center;
    justify-content: center;
    color: inherit;
    transition: all 0.4s ease-out;
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.10), 0 4px 15px 0 rgba(0,0,0,0.08);
    &:hover {
      box-shadow: 0 5px 11px 0 rgba(0,0,0,0.28), 0 4px 15px 0 rgba(0,0,0,0.25);
      transform: translateY(-2px);
    }
    .big-icon {
      font-size: 1.8rem;
      padding-right: 1rem;
      padding-left: 0.5rem;
    }
    .text {
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 1.5rem;
      small {
        font-weight: 400;
      }
    }
  }
  img {
    position: absolute;
    right: -8rem;
    bottom: 0;
    transform: rotateZ(-40deg);
    top: -8rem;
  }

}


/* ------------------------------------- */
/* *. 14 - News Styles ................. */
/* ------------------------------------- */

#news {
  .owl-news {
    padding-top: 4rem;
  }
  figure {
    margin: 0;
    position: relative;
    figcaption {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      .overlay {
        opacity: 0.7;
      }
      .stats {
        z-index: 20;
        position: relative;
        span {
          display: block;
        }
      }
      a,
      span {
        color: #ffffff;
        font-size: 1.3rem;
        font-weight: 500;
      }
      i {
        padding: 0.5rem;
      }
      .search i{
        font-size: 3rem;
        color: #ffffff;
        transform: translateY(-100px);
        transition: transform 0.4s ease-in-out 0.1s;
      }
    }
  }
  figure:hover {
    figcaption {
      opacity: 1;
    }
    .search i{
      transform: translateY(-30px);
    }
  }
  .info {
    margin: -0.4rem auto 0;
    text-align: left;
    padding: 1rem 0 1rem;
    h5 {
      transition: color 0.3s ease-out;
    }
    p {
      margin: 0 ;
    }
    span {
      padding-right: 1rem;
    }
    span i {
      opacity: 1;
      font-size: 1rem;
      color: transparent;
      -webkit-background-clip: text;
      padding-right: 0.5rem;
    }
  }
  h5:hover {
    color: #0b0b0b;
  }
  .shadow-button {
    padding: 0.5rem 2rem;
  }
}


/* ------------------------------------- */
/* *. 15 - Video Styles ................ */
/* ------------------------------------- */

#video {
  position: relative;
  background-size: cover;
  display: flex;
  align-items: center;
  color: #ffffff;
  .overlay {
    opacity: 0.7;
  }
  .container {
    position: relative;
    z-index: 10;
  }
  .reflection-text,
  p{
    color: inherit;
  }
  .icon-button {
    i {
      display: inline-block;
      margin: 15px 30px;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
      text-align: center;
      position: relative;
      z-index: 1;
      color: inherit;
      background: rgba(255, 255, 255, 0.2);
      -webkit-transition: -webkit-transform ease-out 0.1s, background 0.2s;
      -moz-transition: -moz-transform ease-out 0.1s, background 0.2s;
      transition: transform ease-out 0.1s, background 0.2s;
    }

    i:after {
      top: 0;
      left: 0;
      padding: 0;
      z-index: -1;
      box-shadow: 0 0 0 2px rgba(255,255,255,0.1);
      opacity: 0;
      -webkit-transform: scale(0.9);
      -moz-transform: scale(0.9);
      -ms-transform: scale(0.9);
      transform: scale(0.9);
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      content: '';
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
    }
    i:before {
      speak: none;
      line-height: 90px;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      display: block;
      -webkit-font-smoothing: antialiased;
      color: #ffffff;
      font-size: 7rem;
    }
    i:hover {
      background: rgba(255,255,255,0);
      -webkit-transform: scale(0.93);
      -moz-transform: scale(0.93);
      -ms-transform: scale(0.93);
      transform: scale(0.93);
      color: inherit;
    }

    i:hover:after {
      -webkit-animation: sonarEffect 1.3s ease-out 75ms;
      -moz-animation: sonarEffect 1.3s ease-out 75ms;
      animation: sonarEffect 1.3s ease-out 75ms;
    }

    @keyframes sonarEffect {
      0% {
        opacity: 0.3;
      }
      40% {
        opacity: 0.5;
        box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px rgba(39, 38, 39, 0.6), 0 0 0 10px rgba(255,255,255,0.5);
      }
      100% {
        box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px rgba(39, 38, 39, 0.6), 0 0 0 10px rgba(255,255,255,0.5);
        transform: scale(1.5);
        opacity: 0;
      }
    }
  }

}


/* ------------------------------------- */
/* *.16 - Prices Styles ................ */
/* ------------------------------------- */

#prices {
  overflow: hidden;
  .price-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .price {
    position: relative;
    padding: 2rem 1rem 4rem;
    margin: 1rem auto;
    max-width: 450px;
    flex: 1 0;
  }
  .price:not(.best):hover .overlay{
    opacity: 0.3;
  }
  .overlay {
    left: 5%;
    width: 90%;
    margin: 0 auto;
    opacity: 0.1;
    box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    transition: opacity 0.4s ease-out;
  }
  .best {
    color: #ffffff;
  }
  .best .overlay {
    opacity: 0.9;
  }
  h5, h3, li, a {
    color: inherit;
  }
  .info {
    position: relative;
    z-index: 10;
  }
  small {
    font-size: 1rem;
  }
  .sale-box {
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 2rem 1rem 4rem;
    height: 100px;
    width: 100%;
    text-align: center;
    z-index: 10;
    right: 0;
    span.on_sale {
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.65);
      text-transform: uppercase;
      text-align: center;
      display: block;
      position: absolute;
      right: 8%;
      top: 17%;
      transition: all 0.5s ease;
      transform: rotate(40deg);
    }
  }
  hr {
    width: 80%;
    margin: 0 auto;
  }
  ul {
    margin-bottom: 2rem;
  }
  ul li {
    padding: 0.5rem;
  }
  .shadow-button {
    position: absolute;
    padding: 1rem 4rem;
    bottom: 2rem;
    display: block;
    width: 80%;
    left: 10%;
    z-index: 10;
  }
  .best .shadow-button {
    border: 1px solid #ffffff;
  }
}


/* ------------------------------------- */
/* *. 17 - Statistics Styles ........... */
/* ------------------------------------- */

#statistics {
  position: relative;
  background:url("../images/background/balloon.jpg") no-repeat center center fixed;
  background-size: cover;
  align-items: center;
  color: #ffffff;
  .overlay {
    opacity: 0.7;
  }
  .container {
    position: relative;
    z-index: 10;
  }
  h3,
  h4,
  h5,
  i,
  p {
    color: inherit;
  }

  i {
    -webkit-background-clip: text;
    border-radius: 100px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
    width: 100px;
    height: 100px;
    transition: all 0.3s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    &:hover {
      box-shadow: 0 20px 20px rgba(0, 0, 0, 0.22);
    }
  }

}


/* ------------------------------------- */
/* *. 18 - FAQs Styles ................. */
/* ------------------------------------- */

#faq {
  overflow: hidden;
  .faq-image {
    position: relative;
  }
  .absolute-image {
    position: absolute;
    top: -2rem;
    left: -6rem;
  }
  ul li {
    margin: 1rem 0;
  }
  .collapsible-container {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  .collapsible-header {
    position: relative;
    color: #ffffff;
    span {
      font-size: 1.2rem;
    }
  }
  .collapsible-header::after {
    content:"\f067";
    font-family: 'FontAwesome';
    position: absolute;
    right: 1rem;
  }
  .collapsible-header.active::after {
    content:"\f068";
  }
  .plus {
    position: absolute;
    right: 3rem;
  }
}


/* ------------------------------------- */
/* *. 19 - Map Styles .................. */
/* ------------------------------------- */

#map-section {
  margin-bottom: -10px;
  #map {
    height: 800px;
  }
  #map {
    margin: 0;
    padding: 0;
    max-width: none;
  }
  p,
  h5 {
    color: #0b0b0b;
  }
  #iw-container {
    border-radius: 11px !important;
    background-color: rgba(255, 255, 255, 0.97);
  }
  .iw-content {
    margin-right: 1px;
    padding: 15px 5px 20px 15px;
    max-height: 120px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}


/* ------------------------------------- */
/* *. 20 - Footer Styles ............... */
/* ------------------------------------- */

#footer {
  position: relative;
  background: url('../images/concrete_wall.png') repeat;
  padding-bottom: 1rem;
  .footer-main {
    position: relative;
    top: -250px;
    left: 0;
    margin-bottom: -250px;
    overflow: hidden;
    h3 {
      margin-top: 0;
    }
  }
  .main-section {
    width: 90%;
    background: #ffffff;
    margin: 0 auto;
    max-width: 1280px;
  }
  .contact-container {
    display: flex;
    align-items: center;
  }
  form {
    padding: 0 2rem;
    input {
      padding-top: 1rem;
    }
  }
  .prefix {
    padding: 1rem 1rem 0 0;
  }
  .alert-message {
    p {
      text-align: center;
    }
    i {
      font-size: 1.6rem;
      color: #3dbf28;
    }
    .fa-times-circle {
      color: #ff261e;
    }
  }
  button {
    width: 80%;
    padding: 0.5rem 0;
    font-size: 1.4rem;
    color: #ffffff;
    margin-top: 2rem;
    transition: all 0.4s ease-out;
    display: inline-block;
    border: none;
    border-radius: 10px;
    outline: none;
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.10), 0 4px 15px 0 rgba(0,0,0,0.08);
    &:hover {
      box-shadow: 0 5px 11px 0 rgba(0,0,0,0.28), 0 4px 15px 0 rgba(0,0,0,0.25);
      transform: translateY(-2px);
    }
  }
  .address {
    .address-icon {
      padding: 0 1rem;
      font-size: 2rem;
      color: #292929;
    }
    p {
      display: flex;
    }
    a {
      color: inherit;
    }
    a:hover {
      color: #0b0b0b;
    }
    span {
      text-align: left;
    }
  }
  .social-icons {
    text-align: center;
    i {
      border-radius: 100px;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
      font-size: 1.4rem;
      width: 45px;
      height: 45px;
      transition: all 0.3s ease-in;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      margin: 0 0.5rem;
    }
    i:hover {
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.22);
    }
  }
  .footer-content {
    h5 {
      color: #c6c6c6;
    }
    p {
      color: #9b9b9b;
      transition: color 0.3s ease-out;
    }
    a p:hover {
      color: #cdcdcd;
    }
    .chip {
      background-color: #a7a7a7;
      transition: background-color 0.3s ease-out;
    }
    .chip:hover {
      background-color: #e4e4e4;
    }
    .title {
      width: 150px;
    }
    .logo {
      width: 50px;
    }
    .news {
      p {
        margin: 0;
      }
    }
    .insta ul {
      display: flex;
      flex-wrap: wrap;
      li {
        flex: 0 0 40%;
        padding: 0.4rem;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .news img ,
  .insta img {
    filter: grayscale(1);
    transition: all 0.7s ease-in-out;
    &:hover {
      filter: none;
      transform: scale(1.1);
    }
  }
}


/* ------------------------------------- */
/* *. 21 - Copy Rights Styles .......... */
/* ------------------------------------- */

#copy-rights {
  background: url('../images/concrete_wall.png') repeat;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: #e2e2e2;
    padding-bottom: 1rem;
  }
  p {
    color: inherit;
    flex: 1 0 auto;
    text-align: center;
    margin-bottom: 0;
  }
  .social-icons {
    margin-top: 1rem;
    font-size: 1rem;
    flex: 1 0 auto;
    text-align: center;
  }
  i {
    width: 30px;
    height: 30px;
    margin: 0 0.2rem;
    color: #e2e2e2;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
}

/* ------------------------------------- */
/* *. 22 - Animated Demos .............. */
/* ------------------------------------- */

#main-header {
  //Canvas styles for the header of animated demo
  canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 11;
    height: 100%;
  }
  //Particles styles for the header od particles demo
  #particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 100;
  }
  //Video styles for the header of video background demo
  .video {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    overflow: hidden;
  }
  //Waves Demo
  .waveWrapper {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    top: 0;
    margin: auto;
    .waveWrapperInner {
      position: absolute;
      width: 100%;
      overflow: hidden;
      height: 100%;
      bottom: -1px;
    }
    .bgTop {
      z-index: 15;
      opacity: 0.5;
    }
    .bgBottom {
      z-index: 5;
    }
    .wave {
      position: absolute;
      left: 0;
      width: 500%;
      height: 100%;
      background-repeat: repeat no-repeat;
      background-position: 0 bottom;
      transform-origin: center bottom;
    }
    .waveTop {
      background-image: url('../images/background/wave1.png');
      background-size: 40% 100px;
    }
    .waveBottom {
      background-image: url('../images/background/wave1.png');
      background-size: 40% 100px;
    }
    .waveBottom {
      animation: move_wave 25s linear infinite;
    }
  }
}

@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1)
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55)
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1)
  }
}
#main-header.parallax-main-header {
  background: #000000;
  overflow: hidden;
  //Parallax styles for the header of parallax demo
  #svg {
    position: absolute;
    top: 0;
    left: -800px;
    overflow: hidden;
  }
  .layer {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .header-layer {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .header-text {
    width: 100%;
    margin: 0;
    padding: 0;
    top: 45%;
    transform: translateY(-50%);
    left: 0;
    .description {
      max-width: 700px;
      margin: 0 auto;
      padding: 1rem 1.5rem;
    }
  }
  .title-link {
    display: inline-block;
    margin: 0 auto 1rem;
  }
  .parallax {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    .parallax-wrapper:nth-child(1) {
      width: 100%; height: 100%; position: absolute; transform: rotate(-200deg) translate3d(60px, -90px, 50px);
      .parallax-animate {animation: wave 25s 0.2s infinite linear;  width: 100%; height: 100% }
    }
    .parallax-wrapper:nth-child(2) {
      width: 100%; height: 100%; position: absolute; transform: rotate(-120deg) translate3d(20px, 200px, 50px);
      .parallax-animate {animation: wave 35s 0.4s infinite linear;  width: 100%; height: 100% }
    }
    .parallax-wrapper:nth-child(3) {
      width: 100%; height: 100%; position: absolute; transform: rotate(90deg) translate3d(30px, -80px, 50px);
      .parallax-animate {animation: wave 15s 0.6s infinite linear;  width: 100%; height: 100% }
    }
    .parallax-wrapper:nth-child(4) {
      width: 100%; height: 100%; position: absolute; transform: rotate(320deg) translate3d(100px, 230px, 50px);
      .parallax-animate {animation: wave 18s 0.3s infinite linear;  width: 100%; height: 100% }
    }
    .parallax-wrapper:nth-child(5) {
      width: 100%; height: 100%; position: absolute; transform: rotate(300deg) translate3d(-9px, -113px, 72px);
      .parallax-animate {animation: wave 19s 0.6s infinite linear;  width: 100%; height: 100% }
    }
    .parallax-wrapper:nth-child(6) {
      width: 100%; height: 100%; position: absolute; transform: rotate(-20deg) translate3d(20px, 140px, 50px);
      .parallax-animate {animation: wave 20s 0.8s infinite linear;  width: 100%; height: 100% }
    }
    .parallax-wrapper:nth-child(7) {
      width: 100%; height: 100%; position: absolute; transform: rotate(80deg) translate3d(60px, 180px, 50px);
      .parallax-animate {animation: wave 21s 0.3s infinite linear;  width: 100%; height: 100% }
    }
    .parallax-wrapper:nth-child(8) {
      width: 100%; height: 100%; position: absolute; transform: rotate(40deg) translate3d(200px, 350px, 50px);
      .parallax-animate {animation: wave 22s 0.14s infinite linear;  width: 100%; height: 100% }
    }
    .parallax-wrapper:nth-child(9) {
      width: 100%; height: 100%; position: absolute; transform: rotate(-120deg) translate3d(20px, 200px, 50px);
      .parallax-animate {animation: wave 23s 0.17s infinite linear;  width: 100%; height: 100% }
    }
    .parallax-wrapper:nth-child(10) {
      width: 100%; height: 100%; position: absolute; transform: rotate(10deg) translate3d(50px, 120px, 50px);
      .parallax-animate {animation: wave 24s 0.19s infinite linear;  width: 100%; height: 100% }
    }
    .parallax-wrapper:nth-child(11) {
      width: 100%; height: 100%; position: absolute; transform: rotate(10deg) translate3d(12px, 240px, 50px);
      .parallax-animate {animation: wave 25s 0.2s infinite linear;  width: 100%; height: 100% }
    }
    img {
      opacity: 0.7;
    }
  }
  @keyframes wave {
    0% {
      transform: rotateZ(0deg) translate3d(0, 10%, 0) rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg) translate3d(0, 10%, 0) rotateZ(-360deg);
    }
  }
}

/* ------------------------------------- */
/* *. 23 - Home Page ................... */
/* ------------------------------------- */

.homepage {
  #footer {
    padding: 0;
    .footer-main {
      top: 0;
      margin-bottom: 0;
    }
  }
}

/* ------------------------------------- */
/* *. 23 - Other Pages ................... */
/* ------------------------------------- */

.page {
  #main-header {
    height: 300px;
    min-height: 300px;
    .title {
      height: 100%;
      width: 100%;
      text-align: center;
      position: relative;
      background: url('../images/blog-bg.png') no-repeat center bottom;
      overflow: hidden;
    }
    h2 {
      position: relative;
      top: 40%;
      transform: translateY(-50%);
      color: inherit;
    }
  }
  #news figure figcaption .overlay {
    height: 97%;
  }
  .pagination {
    i {
      font-size: 1rem;
      padding: 0 2rem;
    }
    .active {
      background-color: #00e0ff;
    }
  }
  .single-news {
    padding-bottom: 3rem;
    .shadow-button {
      margin-top: 0;
    }
  }
  .content {
    padding: 3rem 1rem 0;
  }
  .sidebar {
    padding: 0 1rem;
    form {
      position: relative;
      padding-left: 0;
      width: 100%;
      input {
        background-color: #ffffff;
        border-radius: 20px;
        color: #0b0b0b;
        margin: 0;
        padding: 0;
        border: 1px solid #9e9e9e;
        border-right: none;
      }
      label {
        padding: 0 2rem;
        font-weight: 500;
        font-size: 1.3rem;
        transform: translateY(7px);
        &::after {
          transform: translateY(75px);
          font-weight: 500;
        }
      }
      label.active {
        transform: translateY(-37px) scale(0.8);
        font-size: 1.3rem;
      }
      button {
        border-radius: 0 30px 30px 0;
        width: 3rem;
        border: none;
        display: inline-block;
        position: absolute;
        right: -1px;
        overflow: hidden;
        top: 0;
        height: 100%;
      }
      i {
        transition: all 0.2s ease-in-out;
        color: #ffffff;
      }
    }
  }
  .btn-large {
    line-height: 56px;
  }
  .collection {
    a {
      width: 100%;
      height: 100%;
      display: block;
      color: #0a0e0a;
      &:hover {
        color: #363537;
        .btn-floating {
          background: #ffffff;
          color: black
        }
      }
    }
    .collection-item {
      min-height: inherit;
    }
    .collection-item:not(.tags):hover {
      background-color: #f6f6f6;
    }
    .tags a {
      width: auto;
      height: auto;
      display: inline-block;
      &:hover {
        background-color: #f6f6f6;
      }
    }
    .social {
      a {
        i {
          margin-top: 0;
          border: none;
          line-height: 2.5rem;
          &:hover {
            color: #ffffff;
          }
        }
      }
    }
    .btn-floating {
      width: 25px;
      height: 25px;
      line-height: 25px;
      font-size: 0.7rem;
      transition: all 0.2s ease-in-out;
    }
  }
  .comments {
    .collection,
    .collection-item {
      border: none;
    }
    .collection-item {
      margin-top: 2rem;
    }
    .reply {
      margin-left: 2rem;
    }
    p {
      margin: 0;
      padding: 0.5rem 0;
    }
    .admin {
      border: 2px solid #f70000;
    }
    a {
      border: 1px solid #0c0c0c;
      padding: 0 1rem;
      display: inline-block;
      width: auto;
      border-radius: 20px;
      transition: all 0.4s ease-out;
      &:hover {
        color: #f1f1f1;
        background: #0d0d0d;
      }
    }
  }
  #comment-form {
    padding: 0 2rem;
    input {
      padding-top: 1rem;
    }
    .prefix {
      padding: 1rem 1rem 0 0;
    }
    .alert-message {
      p {
        text-align: center;
      }
      i {
        font-size: 1.6rem;
        color: #3dbf28;
      }
      .fa-times-circle {
        color: #ff261e;
      }
    }
    button {
      width: 80%;
      padding: 0.5rem 0;
      font-size: 1.4rem;
      color: #ffffff;
      margin-top: 2rem;
      transition: all 0.4s ease-out;
      display: inline-block;
      border: none;
      border-radius: 10px;
      outline: none;
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.10), 0 4px 15px 0 rgba(0, 0, 0, 0.08);
      &:hover {
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.28), 0 4px 15px 0 rgba(0, 0, 0, 0.25);
        transform: translateY(-2px);
      }
    }
  }
}


/* ------------------------------------- */
/* *. 24 - 404 Page .................... */
/* ------------------------------------- */

#not-found {
  background:url("../images/background/aurora.jpg");
  background-size: cover;
  min-height: 100vh;
  position: relative;
  text-align: center;
  .overlay {
    opacity: 0.7;
  }
  .container {
    position: relative;
    min-height: 100vh;
    z-index: 12;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    color: #ffffff;
  }
  h5, a, p, i {
    color: inherit;
  }
  .image {
    align-self: flex-end;
    width: 40%;
    img {
      width: 250px;
      margin: 2rem;
    }
  }
  .main {
    width: 60%;
    text-align: center;
    margin: 2rem 0;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    img {
      width: 250px;
      margin-bottom: 1rem;
    }
  }
  .not-found {
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    margin-bottom: 3rem;
  }
  .slogan {
    border: 1px solid #ffffff;
    border-radius: 10px;
    display: inline-block;
    padding: 0.7rem 2rem;
    letter-spacing: 0.6rem;
    i {
      font-size: 1rem;
      padding-right: 1rem;
      display: inline-flex;
      justify-content: center;
      align-self: center;
    }
  }
}


/* ------------------------------------- */
/* *. 25 - Media Queries ............... */
/* ------------------------------------- */

@media screen and (min-width: 1400px) {
  #main-header {
    .title {
      font-size: 100px;
    }
    .slider .slides li .left-align {
      left: 40%
    }
  }
}

@media screen and (min-width: 600px) {
  //Display Background Images only for big devices
  #features {
    background: url('../images/background/balloon.jpg') no-repeat center center fixed;
    background-size: cover;
  }
  #video {
    background: url('../images/background/balloon.jpg') no-repeat center center fixed;
    background-size: cover;
  }
}

@media screen and (max-width: 1120px) {
  #features {
    .feature-link::after,
    .feature-link::before {
      display: none;
    }
  }
}

@media screen and (max-width: 992px) {
  html {
    font-size: 13px;
  }
  nav .brand-logo {
    left: 8rem;
  }
  .owl-nav {
    display: none;
  }
  #main-nav .button-collapse{
    display: flex;
  }
  #main-header {
    max-height: 1500px;
  }
  #main-header:not(.parallax-main-header){
    height: auto;
    min-height: inherit;
    .header-section {
      flex-direction: column;
      align-self: center;
      justify-content: space-between;
    }
    iframe {
      width: 400px;
      height: 450px;
    }
    .header-text {
      width: 80%;
      margin: 0 auto;
      padding: 5rem 0;
    }
    .owl-header {
      width: 100%;
      max-width: 200px;
      margin: 0 auto;
    }
  }
  #main-header #ripples {
    height: 100%;
    top: 0;
  }
  //For the blog page
  .page {
    #main-header {
      height: 300px;
      min-height: 300px;
    }
    .sidebar {
      padding: 0;
    }
  }
  #features {
    .single-feature {
      padding: 0;
    }
    .owl-features {
      padding: 1rem 0;
    }
    .feature-left .feature-link {
      justify-content: flex-end;
    }
  }
  #showcase .opposite-container {
    display: block;
  }
  #faq .faq-main {
    flex-direction: column;
  }
  #prices .price-container {
    display: block;
    .price:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
  #download {
    text-align: center;
    h3, p {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
  #subscribe .title{
    padding: 0;
  }
  #footer {
    .contact-container {
      display: block;
    }
    .address {
      padding-top: 2rem;
    }
  }
  #not-found .container {
    flex-direction: column;
    .image {
      align-self: center;
      img {
        width: 150px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  html {
    font-size: 80%;
  }
  #main-nav.nav-up {
    top: -52px;
  }
  #subscribe button {
    width: 8rem;
  }
  #faq .faq-image {
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 500px) {
  html {
    font-size: 75%;
  }
}

@media screen and (max-width: 300px) {
  html {
    font-size: 68%;
  }
}


